import { FC, ReactNode, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Outlet } from 'react-router-dom';
import { NoSsr } from '@mui/material';
import { useRouter } from 'next/router';

// Store
import { useSelector } from '../../store';

// Hooks
import useUserConnected from 'components/legacy/hooks/useUserConnected';

// Components
import SplashScreen from 'components/legacy/screens/SplashScreen';

interface AuthGuardProps {
	children?: ReactNode;
}

const HomepageGuard: FC<AuthGuardProps> = ({ children }) => {
	const router = useRouter();
	const [connecting, connected] = useUserConnected();
	const isAuthenticated = useSelector<boolean>((state) => state.user.isAuthenticated);

	useEffect(() => {
		if (!connecting) {
			if (isAuthenticated) {
				router.push(`/city/dashboard/kpi`);
			}
		}
	}, [connecting, isAuthenticated, router]);

	if (connecting || (isAuthenticated && !connecting)) {
		return <SplashScreen />;
	}

	return <NoSsr>{children || <Outlet />}</NoSsr>;
};

export default HomepageGuard;
